@font-face {
  font-family: "RobotoFont";
  src: url("./../../fonts/Roboto/Roboto-Regular.ttf") format("ttf");
  font-weight: normal;
  font-style: normal;
}
* {
  font-family: "RobotoFont";
}
.landing-page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(199, 179, 145, 255);
  position: relative;
  z-index: 10;
  .hero-image-wrapper {
    [href] {
      position: absolute;
      color: black;
      font-size: 24px;
      text-decoration: none;
      top: -8px;
      left: 32px;
      @media screen and (max-width: 600px) {
        font-size: 12px;
        color: black;
        top: -2px;
        left: 16px;
      }
    }
    display: flex;
    justify-content: center;
    width: 100vw;
    height: 70vh;
    position: relative;
    @media screen and (max-width: 600px) {
      height: 100vh;
    }
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
    z-index: 10;
    .hero-image {
      background-image: url("./../BioDataTemplates/images/hero_image.webp");
      height: 70vh; /* Full viewport height */
      width: 70vw;
      [datatype="h1"] {
        color: #800000;
        position: absolute;
        font-size: 64px;
        top: 80px;
        left: 32px;
        width: 360px;
        text-align: left;
        line-height: 60px;
        @media screen and (max-width: 600px) {
          font-size: 30px;
          top: 56%;
          left: 50%;
          transform: translate(-50%, -50%);
          text-align: center;
          width: 270px;
          line-height: 35px;
        }
      }
      [datatype="p"] {
        color: #800000;
        border-radius: 8px;
        position: absolute;
        font-size: 18px;
        bottom: 20%;
        left: 2%;
        width: 300px;
        font-weight: bold;
        text-align: left;

        @media screen and (max-width: 600px) {
          display: none;
          color: black;
          position: absolute;
          text-align: left;
          background-color: rgba(120, 120, 120, 0.5);
          font-weight: bold;
          bottom: 20%;
          left: 50%;
          transform: translate(-50%, 0);
        }
      }

      @media screen and (max-width: 600px) {
        width: 100vw;
        height: 100vh;
      }

      background-size: cover; /* Scale the background image to cover the entire section */
      background-position: center; /* Center the image */
      background-repeat: no-repeat; /* Prevent repeating the image */
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: white; /* Adjust text color if needed */
    }
    .create-biodata-btn {
      background: linear-gradient(
        90deg,
        rgba(99, 10, 33, 1) 24%,
        rgba(236, 3, 3, 0.8225884103641457) 63%,
        rgba(82, 3, 34, 1) 100%
      );

      // background: #800000;
      border-radius: 40px;
      height: 40px;
      position: absolute;
      left: 2%;
      bottom: 6%;
      @media screen and (max-width: 600px) {
        bottom: 15%;
        left: 50%;
        transform: translate(-50%);
      }
    }
  }
  .hero-information {
    color: #800000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 30vh;
    width: 100vw;
    @media screen and (max-width: 600px) {
      width: 100vw;
      height: 70vh;
    }
    background-color: #f8c474;
    [datatype="h1"] {
      font-size: 2.6rem;
      font-weight: 600;
      margin-bottom: 32px;
      @media screen and (max-width: 600px) {
        font-size: 1.3rem;
        text-align: center;
      }
    }
    [datatype="p1"] {
      width: 60%;
      text-align: center;
      font-size: 1.2rem;
      font-weight: 400;
      margin-bottom: 0.5rem;
      line-height: 1.8rem;
      @media screen and (max-width: 600px) {
        width: 80%;
        font-size: 1.3rem;
        text-align: center;
      }
    }
  }
  .form-add-title {
    height: 64px;
    background: linear-gradient(
      90deg,
      rgba(200, 24, 198, 1) 24%,
      rgba(190, 195, 26, 0.8225884103641457) 63%,
      rgba(69, 173, 218, 1) 100%
    );
  }
}

.biodata-form-outer-wrapper {
  background-color: #fdf5e6;
  position: relative;
  width: 100vw;
  height: 100vh;
  @media screen and (max-width: 600px) {
    height: auto;
  }

  .design-image-left {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: rotate(180deg);
    opacity: 0.1; // Set opacity to make it a watermark
    width: 400px; // Set the width of the watermark
    height: auto;
    @media screen and (max-width: 600px) {
      position: fixed;
      bottom: 7px;
      opacity: 0.04;
      z-index: 1;
      // right: 0;
    }
  }
  .biodata-form-wrapper {
    position: relative;
    display: flex;
    column-gap: 16px;
    margin: 0 40px;
    @media screen and (max-width: 600px) {
      // padding: 0 4px;
      width: 100vw;
      margin: 0;
      height: auto;
    }
    width: 100vw;
    height: 100vh;
    .biodata-fields-wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      width: 50%;
      overflow-y: auto;
      scrollbar-width: none; /* For Firefox */
      -ms-overflow-style: none; /* For Internet Explorer and Edge */
      @media screen and (max-width: 600px) {
        width: 100%;
        background: #fdf5e6;
        height: fit-content;
        box-sizing: border-box;
        padding: 0 4px;
      }

      .biodata-field-title-wrapper {
        position: sticky;
        top: 0;
        background-color: #fdf5e6;
        height: 60px;
        display: flex;
        align-items: center;
        z-index: 5;
        margin-bottom: 24px;
        .biodata-field-title {
          font-weight: 700;
          font-size: 1.8rem;
          margin-bottom: 1rem;
          color: #800000;
        }
        @media screen and (max-width: 600px) {
          position: sticky;
        }
      }

      .add-field-btn {
        width: fit-content;
        background: rgba(218, 165, 32, 0.8);
        padding: 0.2rem 1.5rem;
        font-size: 1rem;
        line-height: 31px;
        color: black;
        border-radius: 28px;
        white-space: nowrap;
        cursor: pointer;
        font-weight: 700;
        transition: background-color 0.1s ease-in;
        z-index: 2;

        &:focus {
          background: #e8eaef;
        }
        &:hover {
          background: rgba(218, 165, 32, 1);
          box-shadow: 0px 4px 4px rgba(138, 110, 147, 0.5);
        }
      }

      .form-move-arrows {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 2;
      }
      .form-field-delete-icon {
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .biodata-form-buttons-wrpper {
        display: flex;
        column-gap: 32px;
        justify-content: flex-end;
        margin-bottom: 32px;
        > button {
          width: 320px;
        }
      }
    }
    .biodata-group-wrapper {
      width: 80%;
      @media screen and (max-width: 600px) {
        width: 100%;
      }
    }
  }
}

.profile-image-container {
  width: 50%;
  z-index: 2;
  @media screen and (max-width: 600px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .biodata-profile-picture-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    top: 17rem;
    margin: 180px;
    position: sticky;
    width: 280px;
    height: 400px;
    border-radius: 1rem;
    margin-bottom: 1rem;
    border: 1px solid #e8eaef;
    // background-color: #e8eaef;
    background-color: rgba(218, 165, 32, 0.2);
    @media screen and (max-width: 600px) {
      margin: 0;
    }
    .profile-image-wrapper {
      position: absolute;
      // top: 50px;
      // width: 280px;
      // height: 400px;
      opacity: 0.9;
      z-index: 2;
      width: 391px;
      height: 591px;
      @media screen and (max-width: 600px) {
        width: 272px;
        height: 337.6px;
      }
    }
    @media screen and (max-width: 600px) {
      width: 160px;
      height: 228.6px;
    }
    cursor: pointer;
    > p {
      width: 240px;
      margin-top: 1rem;
      line-height: 1.5rem;
      margin-bottom: 0.8rem;
      text-align: center;
      font-weight: 500;
      color: rgb(100, 114, 140);
      @media screen and (max-width: 600px) {
        width: 160px;
      }
    }
    .biodata-profile-picture {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      top: 8rem;
      position: sticky;
      width: 280px;
      height: 400px;
      border-radius: 16px;
      margin-bottom: 1rem;
      @media screen and (max-width: 600px) {
        width: 160px;
        height: 228.6px;
      }
    }
    .close-btn {
      position: absolute;
      top: -4px;
      right: -4px;
      background-color: rgba(111, 9, 9, 0.8);
      color: white;
      border: none;
      font-size: 8px;
      cursor: pointer;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      // line-height: 25px;
      text-align: center;
      transition: background-color 0.3s ease;
      z-index: 1; /* Ensures the button stays above the image */
    }
    .replace-photo-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 4px;
      position: absolute;
      top: 8px;
      left: 8px;
      background-color: rgba(0, 0, 0, 0.5);
      // opacity: 0.4;
      color: white;
      border: none;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;
      width: 140px;
      height: 36px;
      border-radius: 10px;
      // line-height: 25px;
      text-align: center;
      transition: background-color 0.3s ease;
      z-index: 1; /* Ensures the button stays above the image */
      @media screen and (max-width: 600px) {
        left: 0px;
        top: 0px;
        width: 90px;
        height: 30px;
        border-radius: 6px;
      }
    }

    .close-btn:hover {
      background-color: maroon;
    }
  }
}

.blinking-dot {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  animation: blink 1s infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.todays-count-show-wrapper {
  position: absolute;
  bottom: 70px;
  left: 32px;
  color: black;
  font-weight: bold;
  @media screen and (max-width: 600px) {
    width: 200px;
    height: 16px;
    position: absolute;
    bottom: 18.5%;
    padding: 4px 8px;
    left: 50%;
    transform: translate(-50%, 0);
    color: black;
    border-radius: 4px;
    // background-color: rgba(120, 120, 120, 0.8);
  }
}
